import React, { useEffect, useState } from "react";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import PhoneInput from "react-phone-input-2";
import MaleIcon from "@mui/icons-material/Male";
import FemaleIcon from "@mui/icons-material/Female";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { ls } from "../../../utils/localStorage";
import { getDropwdownValues } from "../../../api/api";

const MainSection = () => {
  const [forData, setForData] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [gender, setGender] = useState(null);
  const [phone, setPhone] = useState("");
  const [DOB, setDOB] = useState(null);
  const [dropdownOptions, setDropdownOptions] = useState(null);
  const data = JSON.parse(ls.getItem("dropdown_values_for_reference"));

  useEffect(() => {
    // fetchDropdownsValues();
  }, []);

  const handleClickRegister = () => {
    console.log("register");
  };

  const fetchDropdownsValues = async () => {
    const response = await getDropwdownValues();
    ls.setItem("dropdown_values_for_reference", JSON.stringify(response.data));
  };

  useEffect(() => {
    if (data != null) {
      setDropdownOptions(data);
      console.log(data);
    }
  }, [data]);

  return (
    <section id="hero" className=" ">
      <div className="container">
        <div className="row">
          <div
            className="col-lg-6 col-md-12 d-lg-flex flex-lg-column align-items-stretch order-1 order-lg-2 hero-img"
            data-aos="fade-up"
          >
            <Box
              sx={{
                bgcolor: "#fff",
                width: "100%",
                height: "100%",
                paddingX: 2,
                paddingY: 6,
                borderRadius: 4,
                display: "grid",
              }}
              gap={2}
            >
              <Box display={"flex"} gap={1} alignItems={"end"} height={"100%"}>
                <FormControlLabel
                  labelPlacement="bottom"
                  sx={{
                    border: "1px solid",
                    px: 2,
                    m: 0,
                    color: `${gender == "Boy" ? "#D53833" : "#000"}`,
                    borderRadius: "5px",
                    height: "100%",
                  }}
                  control={
                    <Checkbox
                      icon={
                        <MaleIcon
                          sx={{
                            width: 24,
                            height: 24,
                          }}
                        />
                      }
                      checkedIcon={
                        <MaleIcon
                          sx={{
                            width: 24,
                            height: 24,
                          }}
                        />
                      }
                      checked={gender == "Boy" ? true : false}
                      sx={{
                        p: 0.5,
                      }}
                    />
                  }
                  onChange={() => setGender("Boy")}
                  label="Boy"
                />
                <FormControlLabel
                  labelPlacement="bottom"
                  sx={{
                    border: "1px solid",
                    px: 2,
                    m: 0,
                    color: `${gender == "Girl" ? "#D53833" : "#000"}`,
                    borderRadius: "5px",
                    fontSize: 12,
                    height: "100%",
                  }}
                  control={
                    <Checkbox
                      size="small"
                      icon={
                        <FemaleIcon
                          sx={{
                            width: 24,
                            height: 24,
                          }}
                        />
                      }
                      checkedIcon={
                        <FemaleIcon
                          sx={{
                            width: 24,
                            height: 24,
                          }}
                        />
                      }
                      checked={gender == "Girl" ? true : false}
                      sx={{
                        p: 0.5,
                      }}
                    />
                  }
                  onChange={() => setGender("Girl")}
                  label="Girl"
                />

                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">For</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="For"
                    sx={{
                      bgcolor: "#fff",
                    }}
                    value={forData}
                    onChange={(e) => setForData(e.target?.value)}
                  >
                    <MenuItem value={1}>Myself</MenuItem>
                    <MenuItem value={2}>Daughter</MenuItem>
                    <MenuItem value={3}>Son</MenuItem>
                    <MenuItem value={4}>Sister</MenuItem>
                    <MenuItem value={5}>Brother</MenuItem>
                    <MenuItem value={6}>Relative</MenuItem>
                    <MenuItem value={7}>Friend</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box
                display={"flex"}
                gap={2}
                flexWrap={{
                  md: "nowrap",
                  xs: "wrap",
                  sm: "wrap",
                }}
              >
                <TextField
                  sx={{
                    bgcolor: "#fff",
                    width: "100%",
                  }}
                  id="outlined-basic"
                  label="First Name"
                  variant="outlined"
                  size="small"
                />

                <TextField
                  sx={{
                    bgcolor: "#fff",
                    width: "100%",
                  }}
                  id="outlined-basic"
                  label="Last Name"
                  variant="outlined"
                  size="small"
                />
              </Box>
              <Box
                display={"flex"}
                gap={2}
                flexWrap={{
                  md: "nowrap",
                  xs: "wrap",
                  sm: "wrap",
                }}
              >
                <PhoneInput
                  country={"in"}
                  value={phone}
                  onChange={(phone) => setPhone(phone)}
                  inputClass="phone-inputClass"
                  placeholder="Mobile"
                />
                <TextField
                  type="email"
                  placeholder="Email"
                  size="small"
                  fullWidth
                />
              </Box>
              <Box
                display={"flex"}
                gap={2}
                flexWrap={{
                  md: "nowrap",
                  xs: "wrap",
                  sm: "wrap",
                }}
              >
                <TextField
                  type="password"
                  size="small"
                  placeholder="Password"
                  fullWidth
                />
                <TextField
                  type="password"
                  size="small"
                  placeholder="Confirm Password"
                  fullWidth
                />
              </Box>
              <Box>
                <Box
                  display={"flex"}
                  gap={2}
                  flexWrap={{
                    md: "nowrap",
                    xs: "wrap",
                    sm: "wrap",
                  }}
                  width={"100%"}
                >
                  <FormControl fullWidth size="small">
                    <InputLabel id="demo-simple-select-label">
                      Region
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Region"
                      sx={{
                        bgcolor: "#fff",
                        width: "100%",
                      }}
                      value={forData}
                      onChange={(e) => setForData(e.target?.value)}
                    >
                      <MenuItem value={1}>Hindhu</MenuItem>
                      <MenuItem value={2}>Musilum</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl fullWidth size="small">
                    <InputLabel id="demo-simple-select-label">Cast</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Cast"
                      sx={{
                        bgcolor: "#fff",
                        width: "100%",
                      }}
                      value={forData}
                      onChange={(e) => setForData(e.target?.value)}
                    >
                      <MenuItem value={1}>1</MenuItem>
                      <MenuItem value={2}>2</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Box>
              <Box
                display={"flex"}
                gap={2}
                flexWrap={{
                  md: "nowrap",
                  xs: "wrap",
                  sm: "wrap",
                }}
                width={"100%"}
              >
                {" "}
                <Box
                  sx={{
                    width: "100%",
                  }}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["DatePicker"]}>
                      <DatePicker
                        value={DOB}
                        onChange={(newValue) => setDOB(newValue)}
                        disableFuture
                        showMonthYearPicker
                        showMonthDropdown
                        slotProps={{ textField: { size: "small" } }}
                        format="DD/MM/YYYY"
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </Box>
              </Box>

              <Button
                className="button"
                type="submit"
                variant="contained"
                color="primary"
                onClick={() => handleClickRegister()}
              >
                Register Free
                <ArrowRightAltIcon />
              </Button>
            </Box>

            {/* <img
              style={{ width: "140%", height: "600px" }}
              src={require("../../../assets/img/img1.gif")}
              className="img-fluid"
              alt=""
            /> */}
          </div>
          {/* <div
              className="col-lg-6 d-lg-flex flex-lg-column justify-content-center align-items-stretch pt-5 pt-lg-0 order-2 order-lg-1 "
              data-aos="fade-up"
            >
              <div>
                <h1>Welcome to Pellifix</h1>
                <h2 className="content-home">
                  Life is the greatest gift humanity has been given, and
                  surviving it is the ultimate test. Having a great partner by
                  your side can make this journey much easier and more
                  enjoyable. Here at Pelli fix, we take great pleasure in
                  helping you find the perfect partner to make your life more
                  glorious.
                </h2>
                <span
                onClick={() => navigate("/login")}
                className="download-btn"
              >
                Login
              </span>
              <span
                onClick={() => navigate("/register")}
                className="download-btn"
              >
                Register
              </span>
              </div>
            </div> */}
        </div>
        <Typography variant="h2">
          Life is the greatest gift humanity has been given, and surviving it is
          the ultimate test. Having a great partner by your side can make this
          journey much easier and more enjoyable. Here at Pelli fix, we take
          great pleasure in helping you find the perfect partner to make your
          life more glorious.
        </Typography>
      </div>
    </section>
  );
};
export default MainSection;
