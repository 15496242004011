import { Avatar, Box, TextField, Typography } from "@mui/material";
import React from "react";
import User from "../../assets/img/profiles/p1.jpg";
import SendIcon from "@mui/icons-material/Send";
import User1 from "../../assets/img/profiles/p5.jpg";
import moment from "moment/moment";

const CustomChat = () => {
  return (
    <>
      <Box
        display={{
          md: "flex",
          sm: "grid",
          xs: "grid",
        }}
        width={"100%"}
        height={"100%"}
        gap={0}
      >
        <Box
          width={{
            md: "30%",
          }}
          borderRight={"1px solid #7777"}
          borderLeft={"1px solid #7777"}
          height={"100%"}
          overflow={"auto"}
        >
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            borderBottom={"1px solid #7777"}
            bgcolor={"#eaeaea "}
            px={1}
            py={0.5}
          >
            <Box display={"flex"} gap={1} alignItems={"center"} width={"100%"}>
              <Avatar src={User} />
              <Box>
                <Typography>Julie</Typography>
                <Typography>Hi How are You?</Typography>
              </Box>
            </Box>
            <Box>
              <Typography fontSize={10} noWrap>
                {moment().format("DD/MM/YYYY HH:MM")}
              </Typography>
            </Box>
          </Box>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            borderBottom={"1px solid #7777"}
            px={1}
            py={0.5}
          >
            <Box display={"flex"} gap={1} alignItems={"center"} width={"100%"}>
              <Avatar src={User1} />
              <Box>
                <Typography>Ann</Typography>
                <Typography>Hi </Typography>
              </Box>
            </Box>
            <Box>
              <Typography fontSize={10} noWrap>
                {moment().subtract(1, "days").format("DD/MM/YYYY HH:MM")}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          width={{
            md: "70%",
          }}
          overflow={"hidden"}
        >
          <Box
            display={"flex"}
            gap={2}
            alignItems={"center"}
            bgcolor={"#a0d2eb "}
            sx={{
              p: 1,
            }}
          >
            <Avatar src={User} />
            <Typography>Julie</Typography>
          </Box>
          <Box sx={{ p: 1 }} height={"90%"}>
            <Box
              bgcolor={"#eaeaea"}
              width={"max-content"}
              p={1}
              sx={{
                borderTopRightRadius: "15px",
                borderBottomLeftRadius: "15px",
              }}
            >
              <Typography>Hi How are You?</Typography>
            </Box>
            <Box
              display={"flex"}
              gap={2}
              alignItems={"center"}
              position="absolute"
              bottom="0px"
              mb={2}
              width={"65%"}
            >
              <TextField size="small" fullWidth />
              <SendIcon
                sx={{
                  color: "#a0d2eb",
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default CustomChat;
